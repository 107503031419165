<template>
 <div>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="60" height="60" viewBox="0 0 60 60">
  <defs>

    <clipPath id="clip-path">
      <rect id="Rectangle_21" data-name="Rectangle 21" style="fill: #fff;" width="38" height="38" transform="translate(450.861 528.861)"/>
    </clipPath>
    <linearGradient id="linear-gradientrtr" y1="1" x2="1.078" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#039be5"/>
      <stop offset="1" stop-color="#31bcff"/>
    </linearGradient>
  </defs>
  <g id="Telegram" transform="translate(-370 -608)">
    <g id="Discord" transform="translate(-80 80)">
      <rect id="Rectangle_21-2" data-name="Rectangle 21" style="fill: #fff;" width="60" height="60" transform="translate(450 528)"/>
    </g>
    <g id="Discord-2" data-name="Discord" transform="translate(-80 79)">
      <g id="Mask_Group_2" data-name="Mask Group 2" style="clip-path: url(#clip-path)" transform="translate(10.139 11.139)">
        <g id="telegram-2" data-name="telegram" transform="translate(450.898 528.898)">
          <circle id="Ellipse_129" data-name="Ellipse 129" style="fill: url(#linear-gradientrtr);" cx="19" cy="19" r="19" transform="translate(-0.037 -0.037)"/>
          <path id="Path_266" data-name="Path 266" style="fill: #fff;" d="M13.194,25.382,31.953,18.15c.871-.315,1.631.212,1.349,1.529l0,0L30.109,34.724c-.237,1.067-.871,1.326-1.758.824l-4.864-3.585-2.346,2.26a1.227,1.227,0,0,1-.981.478l.345-4.95,9.015-8.144c.392-.345-.088-.54-.6-.2l-11.14,7.014-4.8-1.5c-1.043-.331-1.065-1.043.221-1.545Z" transform="translate(-5.24 -7.297)"/>
        </g>
      </g>
    </g>
  </g>
</svg>

 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>